'use client';

import { env } from '@/lib/env';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function DataDogProvider() {
  const isProduction =
    process.env.NODE_ENV === 'production' &&
    ['production' || 'integration'].includes(env.stage);

  useEffect(() => {
    if (!isProduction) return;

    const CLIENT_TOKEN = env.dd.clientToken;
    const APP_ID = env.dd.applicationId;
    const SITE = env.dd.site;
    const VERSION = env.version;
    const ENV = env.stage;

    datadogRum.init({
      applicationId: APP_ID,
      clientToken: CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: SITE,
      service: 'jobreel-website',
      env: ENV,
      version: VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: true,
      defaultPrivacyLevel: 'allow',
    });

    datadogLogs.init({
      clientToken: CLIENT_TOKEN,
      site: SITE,
      env: ENV,
      service: 'jobreel-website',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }, [isProduction]);

  return null;
}
