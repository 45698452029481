import { UpdateUserDto } from '@/modules/user/user.dto';
import {
  getBearerToken,
  getTokenPayload,
  storeBearerToken,
  storeRefreshToken,
  storeUserId,
} from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';
import jobreelApi from '@/lib/utils/jobreel-api';
import { User } from '@/modules/user/user.model';

export const User_Signup = async (phoneNumber: string) => {
  const { data } = await jobreelApi.post<
    ServerResponse<{
      access_token: string;
      refresh_token: string;
      user: User;
    }>
  >(
    '/v1/users',
    { phone_number: phoneNumber },
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  storeBearerToken(data.data.access_token);
  storeRefreshToken(data.data.refresh_token);
  storeUserId(data.data.user.hash);
  return data;
};

export const User_Current = async (): Promise<User> => {
  const { userId } = getTokenPayload();
  return User_Retrieve(userId);
};

export const User_Retrieve = async (hash: string): Promise<User> => {
  const { data } = await jobreelApi.get<ServerResponse<User>>(
    '/v1/users/' + hash,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};

export const User_CurrentUpdate = async (dto: UpdateUserDto) => {
  const payload = getTokenPayload();

  const { data } = await jobreelApi.patch('/v1/users/' + payload.userId, dto, {
    headers: {
      Authorization: getBearerToken(),
    },
  });

  return data;
};
