import { getQueryClient } from '@/components/providers/query-provider';
import jobreelApi from '@/lib/utils/jobreel-api';
import {
  getBearerToken,
  getRefreshToken,
  logout,
  storeBearerToken,
  storeRefreshToken,
  storeUserId,
} from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';

export const Auth_SendOtp = async (phoneNumber: string) => {
  const { status } = await jobreelApi.post<void>('/v1/auth/phone-number', {
    phone_number: phoneNumber,
  });
  return status;
};

export const Auth_Checkcode = async (phoneNumber: string, code: string) => {
  try {
    const { data } = await jobreelApi.post<
      ServerResponse<{
        access_token: string;
        refresh_token: string;
        is_known: boolean;
        user_id?: string;
      }>
    >('/v1/auth/phone-number/check', {
      phone_number: phoneNumber,
      code,
    });
    storeBearerToken(data.data.access_token);
    storeRefreshToken(data.data.refresh_token);
    if (data.data.user_id) {
      storeUserId(data.data.user_id);
    }
    return data.data;
  } catch (e: any) {
    throw e;
  }
};

export const Auth_RefreshToken = async () => {
  try {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      return;
    }

    const { data } = await jobreelApi.post<
      ServerResponse<{
        access_token: string;
        refresh_token: string;
      }>
    >(
      '/v1/auth/refresh',
      {},
      { headers: { 'x-jobreel-refresh': refreshToken } }
    );
    storeBearerToken(data.data.access_token);
    storeRefreshToken(data.data.refresh_token);
  } catch (e: any) {
    await logout();
    return;
  }
};

export const Auth_Logout = async () => {
  const { status } = await jobreelApi.patch<ServerResponse<undefined>>(
    '/v1/auth/logout',
    {},
    { headers: { Authorization: getBearerToken() } }
  );

  const queryClient = getQueryClient();
  await queryClient.invalidateQueries();
  queryClient.removeQueries();
  queryClient.clear();

  if (window !== undefined) {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  return status;
};
