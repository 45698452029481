import { PaginatedResponse } from '@/lib/types';
import { getBearerToken } from '@/lib/utils/functions/authentication';
import jobreelApi from '@/lib/utils/jobreel-api';
import { Reel } from '@/modules/reel/reel.model';

async function findForUser(query?: { limit: number; skip: number }) {
  const { data } = await jobreelApi.get<PaginatedResponse<Reel>>(
    `/v2/reels/findForUser`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
      params: query,
    }
  );
  return data;
}

export const reelService = {
  findForUser,
};
