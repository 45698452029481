'use client';

import { useProfile } from '@/modules/profile/profile.queries';
import { useModalStore } from '@/lib/client/store/modal.store';
import { ProfileInterest } from '@/modules/profile/profile.model';
import { Business_GetCategories } from '@/chore/services/Business.service';
import { Profile_Update } from '@/chore/services/Profile.service';
import MultiSelectButton from '@/components/buttons/MultiSelectButton';
import NextCarretButton from '@/components/buttons/NextCarretButton';
import { Loader } from '@/components/common/loaders/loaders';
import { Colors } from '@/lib/utils/enums/colors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const formId = 'form_update-work-preferences';

export function UpdateWorkPreferencesForm() {
  const { data: profile } = useProfile();
  const { onClose } = useModalStore();
  const queryClient = useQueryClient();
  const { data: categories, isLoading } = useQuery({
    queryKey: [`categories`],
    queryFn: Business_GetCategories,
    refetchInterval: 60 * 60 * 1000,
  });
  const form = useForm<{ categories: ProfileInterest[] }>({
    defaultValues: {
      categories: profile?.interests,
    },
  });

  console.log(categories);

  const selectedCategories = form.watch('categories');

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting || !profile) return;

    if (!values.categories.length) {
      form.setError('categories', {
        message: 'Please include at least 1 work preference.',
      });
      return;
    }

    try {
      await Profile_Update(profile.hash, {
        interests: values.categories.map((cat) => cat.slug),
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`profile-current`],
        }),
        queryClient.invalidateQueries({
          queryKey: [`user-current`],
        }),
      ]);

      onClose();
    } catch (err: any) {
      toast.error(
        isAxiosError(err) ?
          err.response?.data?.message
        : 'Something went wrong. Please try again.'
      );
    }
  });

  const onSelect = (categorySlug: string) => {
    const currentCategories = form.getValues('categories');

    if (currentCategories.some((cat) => cat.slug === categorySlug)) {
      form.setValue(
        'categories',
        currentCategories.filter((cat) => cat.slug !== categorySlug)
      );
    } else {
      const cat = categories?.find((cat) => cat.slug === categorySlug);
      if (cat) currentCategories.push(cat);

      form.setValue('categories', currentCategories);
    }
  };

  return (
    <form id={formId} onSubmit={onSubmit} className='flex flex-col space-y-4'>
      {isLoading ?
        <div className='py-2 flex items-center justify-center'>
          <Loader className='w-8 h-8 text-ruby' />
        </div>
      : <div className='flex flex-col gap-2 max-h-[50svh] overflow-scroll py-1 px-2 overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full'>
          {categories?.map((category) => {
            const checked = !!selectedCategories.some(
              (cat) => cat.slug === category.slug
            );
            return (
              <MultiSelectButton
                key={category.hash}
                value={category.slug}
                selected={checked}
                onSelect={onSelect}
                highlightColor={Colors.Black}
              />
            );
          })}
        </div>
      }
      {errors.categories?.message && (
        <span className='inline-block text-ruby text-sm font-medium'>
          {errors.categories.message}
        </span>
      )}

      <div className='flex justify-center'>
        <NextCarretButton
          type='submit'
          form={formId}
          text='Save'
          isLoading={isSubmitting}
          className='my-0'
        />
      </div>
    </form>
  );
}
