import { User_Current, User_Retrieve } from '@/chore/services/User.service';
import { tokenExists } from '@/lib/utils/functions/authentication';
import { useQuery } from '@tanstack/react-query';

export function useUser() {
  const query = useQuery({
    queryKey: [`user-current`],
    queryFn: User_Current,
    refetchInterval: 30 * 1000,
    enabled: tokenExists(),
  });

  return query;
}

export function useUserByHash(hash: string) {
  const query = useQuery({
    queryKey: [`user-${hash}`],
    queryFn: async () => User_Retrieve(hash),
    enabled: !!hash.length,
  });
  return query;
}
