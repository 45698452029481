import { createFormSlice, FormSlice } from './slices/form.slice';
import { createUploadSlice, UploadSlice } from './slices/upload.slice';
import { createModalSlice, ModalSlice } from './slices/modal.slice';
import { create } from 'zustand';

export type BoundStore = FormSlice & UploadSlice & ModalSlice;

export const craeteBoundStore = () =>
  create<BoundStore>()((...a) => ({
    ...createFormSlice(...a),
    ...createUploadSlice(...a),
    ...createModalSlice(...a),
  }));
