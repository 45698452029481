import { getBearerToken } from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';
import { env } from '@/lib/env';
import jobreelApi from '@/lib/utils/jobreel-api';
import { Reel } from '@/modules/reel/reel.model';

export const Reel_Get = async (reelHash: string) => {
  const { data } = await jobreelApi.get<ServerResponse<Reel>>(
    `/v1/reels/${reelHash}`,
    {
      headers: { Authorization: getBearerToken() },
    }
  );
  return data.data;
};

export const Reel_Create = async (payload: {
  video: string;
  profile?: string;
  job?: string;
}) => {
  const { data } = await jobreelApi.post<ServerResponse<Record<string, any>>>(
    env.baseApiUrl + '/v1/reels',
    payload,
    {
      headers: { Authorization: getBearerToken() },
    }
  );
  return data.data;
};

export const Reel_MuxCreate = async (payload?: {
  job: string;
  business: string;
  profile: string;
}) => {
  const { data } = await jobreelApi.post<
    ServerResponse<{ muxUploadUrl: string; reel: Reel }>
  >(`/v1/reels/mux`, payload ?? {}, {
    headers: {
      Authorization: getBearerToken(),
    },
  });

  return data.data;
};

export const Reel_Attach = async (
  reelHash: string,
  payload: {
    job?: string;
    business?: string;
    profile?: string;
  }
) => {
  const { data } = await jobreelApi.patch<ServerResponse<any>>(
    `/v1/reels/${reelHash}/attach`,
    payload,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};
