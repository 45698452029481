import { StateCreator } from 'zustand';
import { BoundStore } from '..';
import { Profile } from '@/modules/profile/profile.model';

export type ModalType =
  | undefined
  | 'create_experience'
  | 'create_certification'
  | 'update_name_and_city'
  | 'update_job_preferences'
  | 'update_work_preferences'
  | 'apply_overlay'
  | 'job_application_resume';

export type ModalDataType =
  | {
      type?: null | ModalType;
      data?: null;
    }
  | {
      type: 'create_experience';
      data: {
        experienceId?: string;
        buttonType?: 'save' | 'add';
      };
    }
  | {
      type: 'create_certification';
      data: {
        certificationId?: string;
        buttonType?: 'save' | 'add';
      };
    }
  | {
      type: 'update_name_and_city';
      data?: null;
    }
  | { type: 'update_job_preferences'; data?: null }
  | { type: 'update_work_preferences'; data?: null }
  | { type: 'apply_overlay'; data?: null }
  | { type: 'select_business_types'; data?: null }
  | { type: 'job_application_resume'; data: Profile };

export type ModalStore = ModalDataType & {
  open: boolean;
  onOpen: (open: ModalDataType) => void;
  onClose: () => void;
};

export type ModalSlice = {
  modal: ModalStore;
};

export const createModalSlice: StateCreator<BoundStore, [], [], ModalSlice> = (
  set
) => ({
  modal: {
    open: false,
    onOpen: (open) =>
      // @ts-ignore
      set((state) => ({
        ...state,
        modal: {
          ...state.modal,
          open: true,
          type: open.type,
          data: open.data,
        },
      })),
    onClose: () =>
      set((state) => ({
        ...state,
        modal: {
          ...state.modal,
          open: false,
          type: null,
          data: null,
        },
      })),
  },
});
