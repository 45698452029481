'use client';
import { useProfile } from '@/modules/profile/profile.queries';
import { useModalStore } from '@/lib/client/store/modal.store';
import { Profile_Update } from '@/chore/services/Profile.service';
import MultiSelectButton from '@/components/buttons/MultiSelectButton';
import NextCarretButton from '@/components/buttons/NextCarretButton';
import { PositionType } from '@/lib/utils/enums/PositionType';
import { Colors } from '@/lib/utils/enums/colors';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const formId = 'form_edit-job-preferences';

export function UpdateJobPreferencesForm() {
  const { data: profile } = useProfile();
  const { onClose } = useModalStore();
  const queryClient = useQueryClient();
  const form = useForm<{ positions: PositionType[] }>({
    defaultValues: {
      positions:
        !!profile?.openTo.length ? (profile?.openTo as PositionType[]) : [],
    },
  });

  const positions = form.watch('positions');

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting || !profile) return;

    if (!values.positions) {
      form.setError('positions', {
        message: 'You must select at least one option.',
      });
      return;
    }

    try {
      await Profile_Update(profile.hash, { open_to: values.positions });

      toast.success('Job Preferences Updated!');

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`profile-current`],
        }),
        queryClient.invalidateQueries({
          queryKey: [`user-current`],
        }),
      ]);

      onClose();
    } catch (err: any) {
      toast.error(
        isAxiosError(err) ?
          err.response?.data?.message
        : 'Something went wrong. Please try again.'
      );
    }
  });

  const onSelect = (position: PositionType) => {
    if (positions.includes(position)) {
      const p = positions.filter((pos) => pos !== position);
      form.setValue('positions', p);
    } else {
      form.setValue('positions', [...positions, position]);
    }
  };

  if (!profile) return;

  return (
    <form id={formId} className='space-y-4' onSubmit={onSubmit}>
      {Object.values(PositionType).map((type, index) => (
        <MultiSelectButton
          key={index}
          value={type}
          selected={positions.includes(type)}
          onSelect={onSelect}
          highlightColor={Colors.Black}
        />
      ))}

      {errors.positions?.message && (
        <span className='inline-block text-sm text-ruby font-medium'>
          {errors.positions.message}
        </span>
      )}

      <div className='flex items-center justify-center'>
        <NextCarretButton
          type='submit'
          form={formId}
          text='Save'
          disabled={!positions.length}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
}
