'use client';

import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import { useModalStore } from '@/lib/client/store/modal.store';
import { Profile } from '@/modules/profile/profile.model';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { format, parseISO } from 'date-fns';
import { Drawer } from 'vaul';

const content = {
  title: 'Resume',
};

export function JobApplicationResume() {
  const { open, type, onClose, data } = useModalStore();
  const { isMobile } = useIsMobile();

  const isOpen = open && type === 'job_application_resume';

  if (isMobile) {
    return (
      <Drawer.Root
        open={isOpen}
        disablePreventScroll
        onOpenChange={(open) => {
          if (!open) onClose();
        }}
      >
        <Drawer.Portal>
          <Drawer.Overlay className='fixed inset-0 bg-black/60 backdrop-blur-[1px] z-90' />

          <Drawer.Content className='fixed bottom-0 left-0 right-0 flex flex-col rounded-t-[10px] h-fit max-h-[95svh] bg-white py-4 isolate z-100'>
            <div className='flex items-center justify-between px-4 mb-2'>
              <div />
              <Drawer.Handle className='bg-zinc-400' />
              <CloseButton onClose={onClose} />
            </div>

            <div className='flex flex-col text-center px-4'>
              <Drawer.Title className='font-bold text-2xl mb-2'>
                {content.title}
              </Drawer.Title>
            </div>

            <div className='overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full px-4 py-2'>
              {isOpen && data && <Resume profile={data as Profile} />}
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    );
  }

  return (
    <Transition
      show={isOpen}
      enter='ease-out duration-200'
      enterFrom='opacity-0 scale-90'
      enterTo='opacity-100 scale-100'
      leave='ease-in duration-300'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-90'
    >
      <Dialog onClose={onClose} className='relative isolate z-100 transition'>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay
            className='fixed inset-0 bg-black/60 backdrop-blur-[1px] transition'
            aria-hidden='true'
          />
        </Transition.Child>

        <div className='fixed inset-0 flex w-screen items-center justify-center p-4 transition'>
          <Dialog.Panel className='flex flex-col max-w-md w-full max-h-[95svh] border bg-white py-4 rounded-lg transition p-4'>
            <div className='flex flex-col space-y-2'>
              <div className='flex flex-col'>
                <CloseButton onClose={onClose} />
                <Dialog.Title className='font-bold text-2xl'>
                  {content.title}
                </Dialog.Title>
              </div>
            </div>

            <div className='py-4'>
              {isOpen && data && <Resume profile={data as Profile} />}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}

type ResumeProps = {
  profile: Profile;
};

function Resume({ profile }: ResumeProps) {
  if (!profile.experiences.length && !profile.certifications.length) {
    return (
      <div className='h-32 flex items-center justify-center text-center text-zinc-500'>
        <p>No work experience, certificates or degrees have been added.</p>
      </div>
    );
  }

  return (
    <div className='space-y-4'>
      <div>
        {/* <h3 className="text-2xl font-semibold mb-2">
                    {profile.user.name}
                </h3> */}
        <p>{profile.description}</p>
      </div>

      {!!profile.experiences.length && (
        <>
          <Separator />

          <div>
            <h4 className='text-xl font-semibold mb-2'>Experiences:</h4>
            <ul className='pl-2 space-y-2'>
              {profile.experiences.map((exp) => (
                <li key={exp.hash}>
                  <div className='text-lg font-medium'>
                    {exp.role} at <i>{exp.employer}</i>
                  </div>
                  <div>
                    {format(parseISO(exp.startDate.toString()), 'MMM yyyy')} -{' '}
                    {exp.endDate ?
                      format(parseISO(exp.endDate.toString()), 'MMM yyyy')
                    : ''}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {!!profile.certifications.length && (
        <>
          <Separator />

          <div>
            <h4 className='text-xl font-semibold mb-2'>Certifications:</h4>
            <ul className='pl-2 space-y-2'>
              {profile.certifications.map((cert) => (
                <li key={cert.hash}>
                  <div className='text-lg font-medium'>
                    {cert.name} by <i>{cert.issuer}</i>
                  </div>
                  <div>
                    Issue Date:{' '}
                    {!!cert.issued ?
                      format(parseISO(cert.issued.toString()), 'MMMM yyyy')
                    : '-'}
                  </div>
                  <div>
                    Expiration Date:{' '}
                    {!!cert.expiring ?
                      format(parseISO(cert.expiring.toString()), 'MMMM yyyy')
                    : '-'}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

type CloseButtonProps = {
  onClose: () => void;
};

function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <button
      onClick={onClose}
      className='self-end mb-2 bg-transparent rouded-full'
    >
      <XCircleIcon className='w-6 h-6' />
    </button>
  );
}

function Separator() {
  return <div className='w-full h-0.5 bg-zinc-200' />;
}
