import { Auth_RefreshToken } from '@/chore/services/Auth.service';
import axios from 'axios';
import { env } from '@/lib/env';
import { datadogLogs } from '@datadog/browser-logs';

const jobreelApi = axios.create({
  baseURL: env.baseApiUrl,
});

jobreelApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
jobreelApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    datadogLogs.logger.error(error.response.message, { ...error }, error);
    console.error(`${error.response?.status} AXIOS ERROR`, error);

    if (error.response?.status === 401) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Refreshing Auth Token');
      }
      await Auth_RefreshToken();
      if (process.env.NODE_ENV === 'development') {
        console.log('Reperforming request');
      }
      return jobreelApi.request(error.request.config);
    } else {
      return Promise.reject(error);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
);
export default jobreelApi;
