import { Auth_RefreshToken } from '@/chore/services/Auth.service';
import { fromUnixTime, isBefore } from 'date-fns';
import { JWTPayload, decodeJwt } from 'jose';
import { UserStatus } from '@/lib/utils/enums/UserStatus';
import { Keys } from '@/lib/utils/enums/keys';
import { getQueryClient } from '@/components/providers/query-provider';
import { UserType } from '@/lib/types';

const isClient = typeof window !== 'undefined';

export const getBearerToken = (headerStyle = true) => {
  if (!isClient) return;

  const token = window.localStorage.getItem(Keys.AuthenticationToken);

  if (token && headerStyle) {
    return `Bearer ${token}`;
  }

  return token;
};

export const tokenExists = () => {
  if (!isClient) return false;
  return !!window.localStorage.getItem(Keys.AuthenticationToken);
};

export const refreshTokenExists = () => {
  if (!isClient) return false;
  return !!window.localStorage.getItem(Keys.RefreshToken);
};

export const getRefreshToken = () => {
  if (!isClient) return;
  return window.localStorage.getItem(Keys.RefreshToken);
};

export const storeBearerToken = (token: string) => {
  if (!isClient) return;
  window.localStorage.setItem(Keys.AuthenticationToken, token);
};

export const storeRefreshToken = (token: string) => {
  if (!isClient) return;
  window.localStorage.setItem(Keys.RefreshToken, token);
};

export const storeUserId = (userId: string) => {
  if (!isClient) return;
  window.localStorage.setItem(Keys.AuthenticationUser, userId);
};

export const getUserId = (): string | undefined => {
  if (isClient) {
    return window.localStorage.getItem(Keys.AuthenticationUser) ?? undefined;
  }
};

export const getTokenPayload = (): JWTPayload & {
  userId: string;
  role: UserStatus;
  name?: string;
  email?: string;
} => {
  const token = getBearerToken(false);
  return token ?
      (decodeJwt(token) as JWTPayload & {
        userId: string;
        role: UserStatus;
        name?: string;
        email?: string;
      })
    : {
        userId: '',
        role: UserStatus.User,
      };
};

export const maybeRefreshToken = async (): Promise<boolean> => {
  const accessToken = window.localStorage.getItem(Keys.AuthenticationToken);
  const refreshToken = window.localStorage.getItem(Keys.RefreshToken);

  if (!!accessToken && !!refreshToken) {
    let expiry = fromUnixTime(decodeJwt(accessToken).exp!);
    if (isBefore(expiry, new Date())) {
      await Auth_RefreshToken();
      return true;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const storeAuthenticationData = (
  phone_number: string,
  country: string
) => {
  isClient &&
    window.sessionStorage.setItem(
      Keys.AuthenticationData,
      btoa(JSON.stringify({ phone_number, country }))
    );
};

export const getAuthenticationData = (): {
  phone_number: string;
  country: string;
} => {
  const base64data =
    isClient && window.sessionStorage.getItem(Keys.AuthenticationData);
  if (base64data) return JSON.parse(atob(base64data));
  throw new Error('No authentication data found');
};

/* ---  Profile ID --- */

export const getCurrentProfile = () => {
  const profile = isClient && window.localStorage.getItem(Keys.CurrentProfile);
  return profile;
};

export const storeCurrentProfile = (value: string) => {
  isClient && window.localStorage.setItem(Keys.CurrentProfile, value);
};

/* ---  Business ID --- */

export const getCurrentBusiness = () => {
  const business =
    isClient && window.localStorage.getItem(Keys.CurrentBusiness);
  return business;
};

export const storeCurrentBusiness = (value?: string) => {
  if (isClient) {
    if (value) {
      window.localStorage.setItem(Keys.CurrentBusiness, value);
    } else {
      window.localStorage.removeItem(Keys.CurrentBusiness);
    }
  }
};

/* --- Current Job --- */
export const currentJob = (jobId?: string) => {
  if (isClient) {
    if (jobId) {
      window.localStorage.setItem(Keys.CurrentJob, jobId);
    } else {
      return window.localStorage.getItem(Keys.CurrentJob);
    }
  }
};

/* --- Selected Addresses --- */
export const jobAddresses = (addresses?: string[]) => {
  if (isClient) {
    if (addresses) {
      window.localStorage.setItem(Keys.JobAddresses, JSON.stringify(addresses));
    } else {
      let json = window.localStorage.getItem(Keys.JobAddresses);
      if (json) {
        return JSON.parse(json) as string[];
      } else {
        return undefined;
      }
    }
  }
};

/* --- Current Mode --- */
export const currentMode = (mode?: UserType) => {
  if (isClient) {
    if (mode) {
      window.localStorage.setItem(Keys.Mode, mode);
    } else {
      return window.localStorage.getItem(Keys.Mode);
    }
  }
};

/* --- Current Link --- */
export const currentLink = (link?: string) => {
  if (isClient) {
    if (link) {
      window.localStorage.setItem(Keys.CurrentLink, link);
    } else {
      return window.localStorage.getItem(Keys.CurrentLink);
    }
  }
};

export const overlaySeen = (seen?: boolean) => {
  if (isClient) {
    if (seen !== undefined) {
      window.localStorage.setItem(Keys.Overlay, seen.toString());
    } else {
      return window.localStorage.getItem(Keys.Overlay) == 'true';
    }
  }
};

/* --- Logout --- */
export const logout = async () => {
  if (isClient) {
    window.localStorage.removeItem(Keys.AuthenticationToken);
    window.localStorage.removeItem(Keys.RefreshToken);
    window.localStorage.removeItem(Keys.CurrentProfile);
    window.localStorage.removeItem(Keys.CurrentBusiness);
    window.localStorage.removeItem(Keys.CurrentLink);
    window.localStorage.removeItem(Keys.AuthenticationUser);
    window.localStorage.removeItem(Keys.Mode);
    window.localStorage.removeItem(Keys.CurrentJob);
    window.localStorage.removeItem(Keys.JobAddresses);
  }
  const queryClient = getQueryClient();
};

export const isAuthenticated = async () => {
  await Auth_RefreshToken();
  let payload = getTokenPayload();
  return !!(payload.exp && isBefore(new Date(), fromUnixTime(payload.exp)));
};
