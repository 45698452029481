import { env } from '@/lib/env';
import {
  getBearerToken,
  maybeRefreshToken,
} from '@/lib/utils/functions/authentication';

type PlacesApiResult = {
  query: string;
  results: {
    source: string;
    display: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    latitude: number;
    longitude: number;
    rawData: string;
  }[];
};

export const Places_SearchCity = async (city: string) => {
  await maybeRefreshToken();

  const token = getBearerToken();

  const res = await fetch(
    `${env.baseApiUrl}/v1/places/searchCity?${new URLSearchParams({ query: city })}`,
    {
      headers: {
        Authorization: token ?? '',
      },
    }
  );

  if (!res.ok) {
    console.error(await res.json());
    return [];
  }

  const data = (await res.json()) as PlacesApiResult;

  return data.results;
};

export const Places_SearchStreet = async (street: string) => {
  await maybeRefreshToken();
  const token = getBearerToken();

  const res = await fetch(
    `${env.baseApiUrl}/v1/places/searchStreet?${new URLSearchParams({ query: street })}`,
    {
      headers: {
        Authorization: token ?? '',
      },
    }
  );

  if (!res.ok) {
    console.error(await res.json());
    return [];
  }

  const data = (await res.json()) as PlacesApiResult;

  return data.results;
};
