'use client';

import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import { useModalStore } from '@/lib/client/store/modal.store';
import { ApplyButton } from '@/components/buttons/ApplyButton';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { Drawer } from 'vaul';

const content = {
  title: 'Want to get noticed by more employers?',
  description: `Allow employers on Jobreel to view your profile and reach out with opportunities. Only verified employers on Jobreel will be able to see your profile.`,
};

export function ApplyOverlayModal() {
  const { open, type, onClose } = useModalStore();
  const { isMobile } = useIsMobile();

  const isOpen = open && type === 'apply_overlay';

  if (isMobile) {
    return (
      <Drawer.Root
        open={isOpen}
        onClose={onClose}
        shouldScaleBackground
        modal={true}
      >
        <Drawer.Portal>
          <Drawer.Overlay className='fixed inset-0 bg-black/60 backdrop-blur-[1px] z-100' />

          <Drawer.Content className='fixed bottom-0 left-0 right-0 flex flex-col rounded-t-[10px] h-fit max-h-[95svh] bg-white py-4 isolate z-100'>
            <div className='flex items-center justify-between px-4 mb-2'>
              <div />
              <Drawer.Handle className='bg-zinc-400' />
              <CloseButton onClose={onClose} />
            </div>

            <div className='flex flex-col sm:text-center px-4 mb-4'>
              <Drawer.Title className='font-bold text-2xl mb-2'>
                {content.title}
              </Drawer.Title>

              <Drawer.Description>{content.description}</Drawer.Description>
            </div>

            <div className='overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full px-4 py-2'>
              <ApplyOverlayCTA />
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    );
  }

  return (
    <Transition
      show={isOpen}
      enter='ease-out duration-200'
      enterFrom='opacity-0 scale-90'
      enterTo='opacity-100 scale-100'
      leave='ease-in duration-300'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-90'
    >
      <Dialog onClose={onClose} className='relative isolate z-100 transition'>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay
            className='fixed inset-0 bg-black/60 backdrop-blur-[1px] transition'
            aria-hidden='true'
          />
        </Transition.Child>

        <div className='fixed inset-0 flex w-screen items-center justify-center p-4 transition'>
          <Dialog.Panel className='flex flex-col max-w-lg w-full max-h-[95svh] border bg-white py-4 rounded-lg transition p-6'>
            <div className='flex flex-col space-y-2 py-4'>
              <div className='flex flex-col'>
                <CloseButton onClose={onClose} />
                <Dialog.Title className='font-bold text-2xl'>
                  {content.title}
                </Dialog.Title>
              </div>
              <Dialog.Description>{content.description}</Dialog.Description>
            </div>

            <div className='py-4'>
              <ApplyOverlayCTA />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}

export function ApplyOverlayCTA() {
  return (
    <div className='flex flex-col gap-2 w-full'>
      <ApplyButton>Apply to This Job and Post Your Jobreel</ApplyButton>
      <ApplyButton className='bg-white text-ruby border-2 border-ruby hover:bg-zinc-100'>
        Only Apply to This Job
      </ApplyButton>
    </div>
  );
}

type CloseButtonProps = {
  onClose: () => void;
};

function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <button
      onClick={onClose}
      className='self-end mb-2 bg-transparent rouded-full'
    >
      <XCircleIcon className='w-6 h-6' />
    </button>
  );
}
