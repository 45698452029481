'use client';

import {
  Places_SearchCity,
  Places_SearchStreet,
} from '@/chore/services/Places.service';
import { InputV2 } from '@/components/inputs/InputV2';
import { Loader } from '@/components/common/loaders/loaders';
import { Combobox } from '@headlessui/react';
import { cn } from '@/lib/utils/cn';
import MagnifyingGlassIcon from '@heroicons/react/20/solid/MagnifyingGlassIcon';
import MapPinIcon from '@heroicons/react/20/solid/MapPinIcon';
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon';
import { isAxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import { Drawer } from 'vaul';

export type Place = {
  display: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  latitude: number;
  longitude: number;
  source: string;
  rawData: string;
};

type AutocompleteProps = {
  type?: 'city' | 'street';
  label?: string;
  defaultValue?: string;
  onSelect?: (place: Place) => void;
  onChange?: (value: string) => void;
};

export function Autocomplete({
  type = 'city',
  onSelect,
  onChange,
  label,
  defaultValue,
}: AutocompleteProps) {
  const { isMobile } = useIsMobile();
  const [value, setValue] = useState(defaultValue ?? '');
  const [hasFocus, setHasFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<Place[]>([]);
  const mobileInputRef = useRef<HTMLInputElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchAddresses = useCallback(async () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      if (!value.length) {
        setAddresses([]);
        return;
      }

      try {
        setIsLoading(true);
        const results =
          type === 'city' ?
            await Places_SearchCity(value)
          : await Places_SearchStreet(value);
        setAddresses(results);
      } catch (err: any) {
        if (isAxiosError(err)) {
          toast.error(
            err.response?.data.message ??
              'Something went wrong. Please try again later.'
          );
        } else {
          toast.error('Something went wrong. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    }, 150);
  }, [value, type]);

  useEffect(() => {
    fetchAddresses();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [fetchAddresses]);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value, onChange]);

  useEffect(() => {
    if (isMobile) return;
    const cb = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return;
      const autocomplete = e.target.closest('#autocomplete');
      if (!autocomplete) {
        setHasFocus(false);
      }
    };
    window.addEventListener('click', cb);
    return () => {
      window.removeEventListener('click', cb);
    };
  }, [isMobile]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isMobile && !hasFocus) return;
      mobileInputRef.current?.focus();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [isMobile, hasFocus]);

  return (
    <div id='autocomplete' className='relative isolate z-10'>
      <InputV2
        type='text'
        value={value}
        required
        onChange={(e) => setValue(e.target.value)}
        label={label ?? 'Address'}
        onFocus={() => setHasFocus(true)}
      />

      <div
        className={cn(
          'hidden sm:block absolute inset-x-0 bottom-0 translate-y-[calc(100%+2px)] pt-2 px-4 pb-4 rounded-b-lg shadow bg-white scale-y-0 origin-top transition-all',
          value.length > 0 && addresses.length && hasFocus && 'scale-y-100'
        )}
      >
        <ul className='flex flex-col w-full space-y-1'>
          {addresses.map((addr, index) => (
            <li key={index} className=''>
              <button
                type='button'
                className='inline-flex items-center w-full h-14 px-4 rounded-md border transition-colors hover:bg-zinc-100 text-start overflow-x-hidden'
                onClick={() => {
                  setValue(
                    type === 'city' ?
                      `${addr.city}, ${addr.state}`
                    : `${addr.street}, ${addr.city}`
                  );
                  setHasFocus(false);
                  if (onSelect) onSelect(addr);
                }}
              >
                <MapPinIcon className='shrink-0 w-5 h-5 fill-zinc-500 mr-2' />
                <div className='w-full overflow-x-hidden text-ellipsis whitespace-nowrap'>
                  <p className='text-base font-semibold'>
                    {type === 'city' ?
                      addr.city
                    : `${addr.street}, ${addr.city}, ${addr.state}`}
                  </p>

                  <p className='text-sm text-zinc-400'>
                    {type === 'city' ?
                      `${addr.city}, ${addr.state}`
                    : `${addr.street}, ${addr.city}, ${addr.state}`}
                  </p>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <Drawer.Root
        disablePreventScroll
        open={isMobile && hasFocus}
        onClose={() => setHasFocus(false)}
      >
        <Drawer.Portal>
          <Drawer.Overlay className='fixed inset-0 bg-black/60 backdrop-blur-[1px] z-100' />

          <Drawer.Content className='fixed bottom-0 left-0 right-0 flex flex-col rounded-t-[10px] h-full max-h-[95svh] bg-white py-4 sm:py-8 isolate z-100'>
            <div className='relative flex flex-col h-full'>
              <div className='flex items-center justify-between px-4 mb-2'>
                <div />
                <Drawer.Handle className='bg-zinc-400' />
                <Drawer.Close asChild>
                  <CloseButton onClose={() => setHasFocus(false)} />
                </Drawer.Close>
              </div>

              <div className='px-4'>
                <Combobox
                  onChange={(place: Place) => {
                    setValue(
                      type === 'city' ?
                        `${place.city}, ${place.state}`
                      : `${place.street}, ${place.city}, ${place.state}`
                    );
                    setHasFocus(false);
                    if (onSelect) onSelect(place);
                  }}
                >
                  <div className='relative mb-4'>
                    <MagnifyingGlassIcon className='absolute top-1/2 left-4 -translate-y-1/2 w-4 h-4' />
                    <Combobox.Input
                      ref={mobileInputRef}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      className='w-full rounded-full pl-10 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-black focus:outline-none'
                      placeholder='New York, New York'
                    />
                  </div>

                  {isLoading && (
                    <div className='flex items-center justify-center'>
                      <Loader className='w-8 h-8 text-ruby' />
                    </div>
                  )}

                  <Combobox.Options className='overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full max-h-[75svh] pb-8'>
                    {addresses.map((addr, i) => (
                      <Combobox.Option
                        key={i}
                        value={addr}
                        className='flex items-center py-2 px-4 border-b transition-colors hover:bg-zinc-50'
                      >
                        <MapPinIcon className='w-5 h-5 fill-zinc-500 mr-2' />
                        <div>
                          <p className='text-base font-semibold'>
                            {type === 'city' ?
                              addr.city
                            : `${addr.street}, ${addr.city}, ${addr.state}`}
                          </p>
                          <p className='text-sm text-zinc-400'>
                            {type === 'city' ?
                              `${addr.city}, ${addr.state}`
                            : `${addr.street}, ${addr.city}, ${addr.state}`}
                          </p>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox>
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  );
}

type CloseButtonProps = {
  onClose: () => void;
};

function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <button
      onClick={onClose}
      className='self-end mb-2 bg-transparent rounded-full focus-visible:outline-none focus-visible:fing-1 focus-visible:ring-black'
    >
      <XCircleIcon className='w-6 h-6' />
    </button>
  );
}
