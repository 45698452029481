import { useCertification } from '@/modules/profile/profile.queries';
import { useModalStore } from '@/lib/client/store/modal.store';
import { UpsertCertificationDto } from '@/modules/profile/profile.dto';
import {
  Certification_Create,
  Certification_Update,
} from '@/chore/services/Profile.service';
import NextCarretButton from '@/components/buttons/NextCarretButton';
import { InputV2 } from '@/components/inputs/InputV2';
import { Loader } from '@/components/common/loaders/loaders';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { format, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const formId = 'form_create-certification';

type CreateCertificationFormProps = {
  buttonType?: 'add' | 'save';
  onSuccess?: () => Promise<void> | void;
  onStatusChange?: (status: {
    isDirty: boolean;
    isSubmitting: boolean;
  }) => Promise<void> | void;
};

export function CreateCertificationForm({
  buttonType,
  onSuccess,
  onStatusChange,
}: CreateCertificationFormProps) {
  const queryClient = useQueryClient();
  const { data, type, onClose } = useModalStore();
  const { data: certification, isLoading } = useCertification(
    type === 'create_certification' ? data?.certificationId ?? '' : ''
  );
  const form = useForm<UpsertCertificationDto>({
    defaultValues: {
      name: certification?.name ?? '',
      issuer: certification?.issuer ?? '',
      issued_at:
        !!certification?.issued ?
          format(parseISO(certification.issued.toString()), 'yyyy-MM')
        : '',
      expiring_on:
        !!certification?.expiring ?
          format(parseISO(certification.expiring.toString()), 'yyyy-MM')
        : '',
      url: certification?.url ?? '',
    },
    values: {
      name: certification?.name ?? '',
      issuer: certification?.issuer ?? '',
      issued_at:
        !!certification?.issued ?
          format(parseISO(certification.issued.toString()), 'yyyy-MM')
        : '',
      expiring_on:
        !!certification?.expiring ?
          format(parseISO(certification.expiring.toString()), 'yyyy-MM')
        : '',
      url: certification?.url ?? '',
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, isDirty, errors },
  } = form;

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange({ isDirty, isSubmitting });
    }
  }, [isDirty, isSubmitting, onStatusChange]);

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting) return;

    if (
      !!values.issued_at &&
      !!values.expiring_on &&
      new Date(values.issued_at).getTime() >
        new Date(values.expiring_on).getTime()
    ) {
      form.setError('issued_at', {
        message: 'Issue Date must be previous to Expiration Date',
      });
      form.setError('expiring_on', {
        message: 'Expiration Date must be greater than Issue Date',
      });
      return;
    }

    try {
      if (certification) {
        await Certification_Update(certification.hash, values);
      } else {
        await Certification_Create(values);
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`profile-current`],
        }),
        queryClient.invalidateQueries({
          queryKey: ['certifications'],
        }),
      ]);
      if (certification) {
        await queryClient.invalidateQueries({
          queryKey: [`certifications-${certification.hash}`],
        });
      }

      reset();
      onClose();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (err: any) {
      if (isAxiosError(err)) {
        return toast.error(
          err.response?.data?.message ?? 'Something went wrong'
        );
      }

      toast.error('Something went wrong');
    }
  });

  if (isLoading) {
    return (
      <div className='flex justify-center py-2'>
        <Loader className='w-8 h-8 text-ruby' />
      </div>
    );
  }

  return (
    <form id={formId} className='space-y-4 w-full' onSubmit={onSubmit}>
      <div className='space-y-2'>
        <InputV2
          label='Certification or Degree Name'
          required
          defaultValue={form.getValues('name')}
          {...register('name', { required: true, minLength: 1 })}
        />
        <ErrorMessage
          message={
            errors.name?.type === 'required' ?
              'Certification or Degree Name is required.'
            : errors.name?.message
          }
        />
      </div>

      <div className='space-y-2'>
        <InputV2
          label='Issuing Organization'
          required
          defaultValue={form.getValues('issuer')}
          {...register('issuer', { required: true, minLength: 1 })}
        />
        <ErrorMessage
          message={
            errors.issuer?.type === 'required' ?
              'Issuing Organization is required.'
            : errors.issuer?.message
          }
        />
      </div>

      <div className='space-y-2'>
        <InputV2 label='Issue Date' type='month' {...register('issued_at')} />
        <ErrorMessage message={errors.issued_at?.message} />
      </div>

      <div>
        <InputV2
          label='Expiration Date'
          type='month'
          {...register('expiring_on')}
        />
        <ErrorMessage message={errors.expiring_on?.message} />
      </div>

      <div className='space-y-2'>
        <InputV2
          label='Credential ID or URL'
          defaultValue={form.getValues('url')}
          {...register('url')}
        />
        <ErrorMessage message={errors.url?.message} />
      </div>

      <div className='flex justify-center w-full'>
        {(
          (type === 'create_certification' && data?.buttonType === 'add') ||
          buttonType === 'add'
        ) ?
          <button
            type='submit'
            form={formId}
            disabled={isSubmitting}
            className='self-center inline-flex items-center justify-center text-ruby font-medium text-lg px-3 py-2 rounded-full transition-colors hover:bg-zinc-100 focus-visible:outline-none focus-visible:ring focus-visible:ring-black'
          >
            {isSubmitting ?
              <Loader className='w-5 h-5' />
            : '+ Add Another'}
          </button>
        : <NextCarretButton
            text='Save Certification'
            type='submit'
            form={formId}
            className='w-full justify-center items-center'
            isLoading={isSubmitting}
          />
        }
      </div>
    </form>
  );
}

type ErrorMessageProps = {
  message?: string;
};

function ErrorMessage({ message }: ErrorMessageProps) {
  if (!message) return null;
  return <p className='text-sm text-ruby'>{message}</p>;
}
