'use client';

import { useEffect, useState } from 'react';
import { CreateExperienceModal } from './create-experience';
import { CreateCertificationModal } from './create-certification';
import { UpdateNameAndCityModal } from './update-name-and-city';
import { UpdateJobPreferencesModal } from './update-job-preferences';
import { UpdateWorkPreferencesModal } from './update-work-preferences';
import { ApplyOverlayModal } from './apply-overlay';
import { JobApplicationResume } from './job-application-resume';

export function ModalProvider() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  if (!isMounted) return null;

  return (
    <>
      <ApplyOverlayModal />
      <CreateExperienceModal />
      <CreateCertificationModal />
      <UpdateNameAndCityModal />
      <UpdateJobPreferencesModal />
      <UpdateWorkPreferencesModal />
      <JobApplicationResume />
    </>
  );
}
