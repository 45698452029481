'use client';

import { useUser } from '@/modules/user/user.queries';
import { useModalStore } from '@/lib/client/store/modal.store';
import { User_CurrentUpdate } from '@/chore/services/User.service';
import { Autocomplete, Place } from '@/components/common/autocomplete';
import NextCarretButton from '@/components/buttons/NextCarretButton';
import { InputV2 } from '@/components/inputs/InputV2';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const formId = 'form_update-name-and-city';

type UpdateNameAndCityFormProps = {
  onSuccess?: () => void | Promise<void>;
};

export function UpdateNameAndCityForm({
  onSuccess,
}: UpdateNameAndCityFormProps) {
  const { onClose } = useModalStore();
  const { data: user } = useUser();
  const queryClient = useQueryClient();
  const [place, setPlace] = useState<Place | null>();
  const form = useForm<{ name: string; address: string }>({
    defaultValues: {
      name: user?.name ?? '',
      address:
        !!user?.placeRawData ?
          `${user.placeRawData.city}, ${user.placeRawData.administrative}`
        : '',
    },
    values: {
      name: user?.name ?? '',
      address:
        !!user?.placeRawData ?
          `${user.placeRawData.city}, ${user.placeRawData.administrative}`
        : '',
    },
  });

  const name = form.watch('name');

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = form;

  useEffect(() => {
    if (user?.placeRawData) {
      setPlace({
        city: user.placeRawData.city,
        state: user.placeRawData.administrative ?? '',
        latitude: user.placeRawData.lat,
        longitude: user.placeRawData.lng,
        street: '',
        zipCode: user.placeRawData.zipcode?.at(0) ?? '',
        source: user.placeSource ?? '',
        country: user.placeRawData.country,
        display: '',
        rawData: JSON.stringify(user.placeRawData),
      });
    }
  }, [user?.placeRawData, user?.placeSource]);

  const onSubmit = handleSubmit(async (values) => {
    if (isSubmitting) return;

    if (!user) return;

    if (!values.name.length) {
      form.setError('name', { message: 'Your full name is required' });
      return;
    }

    if (!place) {
      form.setError('address', {
        message: 'Please select a place from the dropdown',
      });
      return;
    }

    try {
      await User_CurrentUpdate({ name: values.name, placeResult: place });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [`user-current`],
        }),
        queryClient.invalidateQueries({
          queryKey: [`profile-current`],
        }),
      ]);

      toast.success('Name and City updated!');

      if (onSuccess) {
        await onSuccess();
      }

      onClose();
    } catch (err: any) {
      toast.error(
        isAxiosError(err) ?
          err.response?.data?.message
        : 'Something went wrong. Please try again.'
      );
    }
  });

  const onSelectAddress = (place: Place) => {
    setPlace(place);
    form.setValue('address', `${place.city}, ${place.state}`);
  };

  if (!user) {
    return null;
  }

  return (
    <form id={formId} onSubmit={onSubmit} className='space-y-4'>
      <div className='space-y-2'>
        <InputV2
          label='Full Name'
          required
          defaultValue={user.name}
          {...register('name', { required: true, min: 1 })}
        />
        <ErrorMessage message={errors.name?.message} />
      </div>

      <div className='space-y-2'>
        <Autocomplete
          label='City & State'
          type='city'
          onSelect={onSelectAddress}
          defaultValue={
            user.placeRawData ? `${user.city}, ${user.state}` : undefined
          }
          onChange={(v) => form.setValue('address', v)}
        />
        <ErrorMessage message={errors.address?.message} />
      </div>

      <div className='flex items-center justify-center'>
        <NextCarretButton
          type='submit'
          form={formId}
          text={`Save`}
          disabled={!name.length || !place}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
}

function ErrorMessage({ message }: { message?: string }) {
  if (!message?.length) {
    return null;
  }

  return (
    <span className='inline-block text-ruby text-sm font-medium'>
      {message}
    </span>
  );
}
