import { Reel_Get } from '@/chore/services/Reel.service';
import { reelService } from '@/chore/services/v2/Reel.service';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

export function useReel(reelHash: string) {
  const query = useQuery({
    queryKey: [`reels-${reelHash}`],
    queryFn: async () => Reel_Get(reelHash),
    refetchInterval: (options) => {
      const status = options.state.data?.muxStatus;

      if (status === 'created') {
        return 5 * 1000;
      }
      if (status === 'preparing') {
        return 1000;
      }
      return false;
    },
    enabled: !!reelHash.length,
  });
  return query;
}

export function useFindReelsForUser(query?: { limit: number; skip: number }) {
  return useInfiniteQuery({
    queryKey: [`user-reels`],
    queryFn: async ({ pageParam }) => {
      return reelService.findForUser({
        limit: query?.limit ?? 20,
        skip: pageParam * (query?.skip ?? query?.limit ?? 20),
      });
    },
    initialPageParam: 0,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage.hasMore) {
        return;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return;
      }
      return firstPageParam - 1;
    },
  });
}
